<template>
  <aside class="si-side-filters">
    <h2 class="si-side-filters__section si-side-filters__section--first" :class="{ 'si-side-filters__section--fullscreen': fullscreen }">{{ $t('filters.offerings') }}</h2>
    <ul>
      <filter-group :fullscreen="fullscreen" class="si-side-filters__group" name="offerings" :title="title" :filters="filters" :value="offerings" v-for="(filters, title) in filters.offerings" @filter="values => updateOfferings(filters, values)"/>
    </ul>

    <h2 class="si-side-filters__section" :class="{ 'si-side-filters__section--fullscreen': fullscreen }">{{ $t('filters.others') }}</h2>
    <ul>
      <filter-group :fullscreen="fullscreen" class="si-side-filters__group" name="canton" :title="$t('filters.canton')" :filters="filters.canton" update-query/>
      <filter-group :fullscreen="fullscreen" class="si-side-filters__group" name="audiences" :title="$t('filters.audiences')" :filters="filters.audiences" update-query/>
      <filter-group :fullscreen="fullscreen" class="si-side-filters__group" name="languages" :title="$t('filters.languages')" :filters="filters.languages" update-query/>
      <filter-group :fullscreen="fullscreen" class="si-side-filters__group" name="others" :title="others" :filters="filters.others" update-query/>
      <filter-group :fullscreen="fullscreen" class="si-side-filters__group" name="coverages" :title="$t('filters.coverages')" :filters="filters.coverages" update-query/>
      <filter-group :fullscreen="fullscreen" class="si-side-filters__group" name="certificates" :title="$t('filters.certificates')" :filters="filters.certificates" update-query/>
    </ul>
  </aside>
</template>

<script>
import FilterGroup from './FilterGroup';
import updateQuery from '../../helpers/updateQuery';

export default {
  components: { FilterGroup },
  inject: ['filters', 'others'],

  props: {
    fullscreen: Boolean,
  },

  data: () => ({
    offerings: [],
  }),

  computed: {
    query: (vm) => vm.$route.query,
  },

  methods: {
    updateOfferings (filters, values) {
      const filterIds = filters.map(o => Number(o.value));
      const ids = ([].concat(...Object.values(this.filters.offerings)).map(o => Number(o.value)));

      const offerings = this.offerings
          .filter(id => ids.includes(id) && !filterIds.includes(id))
          .concat(values.map(id => Number(id)));

      this.$router.push(updateQuery(this.$route, 'offerings', offerings));
    },

    loadQuery () {
      this.offerings = this.query.offerings ? this.query.offerings.split(',').map(id => Number(id)) : [];
    },
  },

  watch: {
    query () {
      this.loadQuery();
    }
  },

  mounted () {
    this.loadQuery();
  }
}
</script>

<style lang="scss">
@import "../../../styles/defaults";

.si-side-filters {
  &__section {
    margin: 0 !important;
    padding: 30px 10px 15px;
    font-size: 15px;
    color: map-get($font-color, light);

    &--first {
      margin-top: 15px;
    }

    &--fullscreen {
      padding-left: 50px;
    }
  }

  > ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
}

@include media(desktop, '.si-side-filters') {
  border-top: 1px solid map-get($borders, default);
}

@include media(1681, '.si-side-filters') {
  margin-left: 35px;

  &__section--fullscreen {
    padding-left: 15px;
  }
}
</style>
