import { reactive } from 'vue';

const media = {
    isMobile: '(max-width: 766px)',
    isTablet: '(min-width: 767px) and (max-width: 1219px)',
    isDesktop: '(min-width: 1220px)',
};

export function createMedia () {
    return {
        install: (app) => {
            app.config.globalProperties.$screen = reactive({});

            const setMedia = (property) => {
                Object.keys(media).forEach((p) => {
                    app.config.globalProperties.$screen[p] = p === property;
                })
            }

            Object.keys(media).forEach((k) => {
                const match = window.matchMedia(media[k]);

                if (match.matches) {
                    setMedia(k);
                }

                match.addEventListener('change', (e) => {
                    if (e.matches) {
                        setMedia(k)
                    }
                });
            });
        }
    }
}
