<template>
  <ul class="si-location-languages" :class="{ 'si-location-languages--inline': inline }">
    <li v-for="language in languages">{{ language.label }}</li>
  </ul>
</template>

<script>
export default {
  inject: ['filters'],

  props: {
    id: Number,
    inline: Boolean,
  },

  computed: {
    location: (vm) => vm.$store.locations.all?.find(l => Number(l.id) === Number(vm.id)),
    languages: (vm) => vm.location.languages.map(id => vm.filters.languages.find(l => l.value === id)),
  },
}
</script>

<style lang="scss">
@import "../../../styles/defaults";

.si-location-languages {
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    margin: 0;
    padding: 0;
  }

  &--inline {
    display: flex;
    flex-wrap: wrap;

    li {
      margin-right: 5px;

      &:after {
        content: ",";
      }

      &:last-child:after {
        content: none;
      }
    }
  }
}
</style>
