<template>
  <swisstopo-search
      :placeholder="$t($screen.isMobile ? 'place.title' : 'place.placeholder')"
      :overlay-title="$t('place.title')"
      :cantons="cantons"
      ref="search"
      v-model="value"
  />
</template>

<script>
import SwisstopoSearch from '../Fragments/SwisstopoSearch.vue';
import updateQuery from '../../helpers/updateQuery';

export default {
  components: { SwisstopoSearch },

  inject: ['filters'],

  data: () => ({
    value: null,
  }),

  computed: {
    place: (vm) => vm.$store.place,
    route: (vm) => vm.$route.query.place,
    cantons: (vm) => Object.fromEntries(vm.filters.canton.map(c => [c.value, c.title])),
  },

  watch: {
    value (result) {
      if (result && !result.postal) {
        this.$store.place = result;
        this.$router.push(updateQuery(this.$route, 'place', `${ result.label }|${ result.lat },${ result.lng }|${ result.canton }`));
      } else if (result) {
        this.$store.place = result;
        this.$router.push(updateQuery(this.$route, 'place', `${ result.label }|${ result.lat },${ result.lng }`));
      } else {
        this.$store.place = null;

        if (this.$route.query.place) {
          this.$router.push(updateQuery(this.$route, 'place'));
        }
      }
    },

    place () {
      this.value = this.place;
    },

    route () {
      if (this.place && !this.route) {
        this.$store.place = null;
      }
    }
  },

  async mounted () {
    if (this.$route.query.place) {
      const location = this.$route.query.place.split('|');

      if (location.length === 3) { // Canton
        const result = await this.$refs.search.fetchCanton(...location[1].split(','))
        this.$refs.search.select(result);
      } else {
        const result = await this.$refs.search.fetchPlace(...location[1].split(','))
        this.$refs.search.select(result);
      }
    }
  }
}
</script>
