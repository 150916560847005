<template>
  <li class="si-side-filter-group widget">
    <button class="si-side-filter-group__toggle" :class="{ 'si-side-filter-group__toggle--expanded': expanded, 'si-side-filter-group__toggle--fullscreen': fullscreen }" :aria-controls="ariaId" :aria-expanded="expanded ? 'true' : 'false'" @click="expanded=!expanded">
      <span class="si-side-filter-group__title">{{ title }}</span>
      <span class="si-side-filter-group__active" aria-hidden="true" v-show="selection.length"></span></button>
    <fieldset class="checkbox_container si-side-filter-group__options" :class="{ 'si-side-filter-group__options--fullscreen': fullscreen }" :id="ariaId" v-show="expanded">
      <span class="si-side-filter-group__option" v-for="filter in filterOptions">
        <input class="si-side-filter-group__checkbox" :id="`${ariaId}_${filter.value}`" type="checkbox" :value="filter.value" :disabled="!optionSelected(filter) && count[filter.value] === 0" :checked="optionSelected(filter)" @change="updateSelection">
        <label class="si-side-filter-group__label" :for="`${ariaId}_${filter.value}`">
          {{ filter.label }}
          <span class="si-side-filter-group__count" v-if="count[asValue(filter.value)] !== undefined">{{ count[asValue(filter.value)] }}</span>
        </label>
      </span>
    </fieldset>
  </li>
</template>

<script>
import asValue from '../../helpers/asValue';
import filter from '../../helpers/filter';
import randomName from '../../helpers/randomName';
import updateQuery from '../../helpers/updateQuery';
import idle from '../../helpers/idle';

export default {
  inject: ['activeFilters'],

  props: {
    name: String,
    updateQuery: Boolean,
    title: String,
    filters: Array,
    value: Array,
    fullscreen: Boolean,
  },

  data: () => ({
    ariaId: randomName('filter-group-'),
    selection: [],
    expanded: false,

    debounceCount: null,
    count: {},
  }),

  computed: {
    query: (vm) => vm.$route.query,
    optionSelected: (vm) => (option) => vm.selection.includes(asValue(option.value)),

    filterOptions () {
      return this.filters.map((option) => ({
        value: option.value,
        label: option.label,
      }));
    },
  },

  methods: {
    asValue,

    loadQuery () {
      if (!this.updateQuery) {
        return;
      }

      if (!this.query[this.name]) {
        if (this.selection.length) {
          this.selection = [];
        }
        return;
      }

      const values = this.query[this.name].split(',').map(asValue);
      const selection = this.filters.map(o => o.value).filter(k => values.includes(k));

      if (this.selection.length !== selection.length) {
        this.selection = selection;
      }
    },

    updateSelection (event) {
      const value = asValue(event.target.value);

      if (event.target.checked) {
        this.selection.push(value);
      } else {
        this.selection = this.selection.filter(v => v !== value);
      }

      this.$emit('filter', this.selection);

      if (this.updateQuery) {
        this.$router.push(updateQuery(this.$route, this.name, this.selection));
      }
    },

    updateCounts () {
      if (this.debounceCount) {
        this.debounceCount();
      }

      this.debounceCount = idle(() => {
        const counts = {};
        this.filters.forEach((option) => {
          const filters = Object.assign({}, this.activeFilters);

          delete filters[this.name];
          filters[this.name] = [option.value];

          counts[option.value] = filter(this.$store.locations.all, filters, this.$store.place).length;
        });

        this.count = counts;
      });
    }
  },

  watch: {
    value () {
      this.selection = this.filters.map(o => o.value).filter(k => this.value.includes(k));
    },

    query () {
      this.loadQuery();
    },

    activeFilters () {
      this.updateCounts();
    }
  },

  mounted () {
    if (this.value) {
      this.selection = this.filters.map(o => o.value).filter(k => this.value.includes(k));
    }

    this.loadQuery();
    this.updateCounts();
  }
}
</script>

<style lang="scss">
@import "../../../styles/defaults";

.si-side-filter-group {
  margin: 0 !important;
  border-bottom: 1px solid map-get($borders, light);

  &__toggle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 12px 40px 12px 10px;
    font-size: 19px;
    text-align: left;
    background: none;
    border: none;

    &:hover {
      background: map-get($backgrounds, service);
    }

    &:after {
      content: "";
      position: absolute;
      right: 10px;
      top: 50%;
      width: 20px;
      height: 10px;
      margin-top: -5px;
      background: url("../../../images/select.svg") 0 0/contain no-repeat;
      transition: transform .1s ease-in-out;
      transform-origin: center center;
    }

    &--fullscreen {
      padding-left: 50px;
    }

    &--expanded {
      &:after {
        transform: rotateZ(180deg);
      }
    }
  }

  &__title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__active {
    width: 8px;
    height: 8px;
    margin-left: 8px;
    background: map-get($backgrounds, button);
    border-radius: 50%;
    flex-shrink: 0;
  }

  &__options {
    padding: 5px 0 25px;

    &--fullscreen {
      padding-left: 50px;
    }
  }

  &__option {
    padding: 9px 10px;
  }

  &__count {
    position: relative;
    top: -4px;
    left: 4px;
    font-size: 14px;
  }
}

@include media(tablet, '.si-side-filter-group') {
  border-bottom-color: map-get($borders, default);
}

@include media(1681, '.si-side-filter-group') {
  &__toggle--fullscreen,
  &__options--fullscreen {
    padding-left: 15px;
  }
}
</style>
