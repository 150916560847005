<template>
  <button
      type="button"
      class="si-location__watchlist"
      data-controller="watchlist-state"
      :data-watchlist-state-id-value="id" :data-watchlist-state-add-title-value="$t('watchlist.add')" :data-watchlist-state-remove-title-value="$t('watchlist.remove')"
      data-watchlist-state-active-class="si-location__watchlist--active"
      data-action="watchlist-state#toggle"
  ></button>
</template>

<script>
export default {
  props: {
    id: Number,
  },
}
</script>
