<template>
  <div class="si-location" :class="{ 'si-location--list': listView, 'si-location--highlight': !listView && highlight === id }"
       @mouseenter="mouseenter" @mouseleave="mouseleave"
       v-if="location && location.name"
  >
      <div class="si-location__about" :class="{ 'si-location__contact--list': listView }">
        <a class="si-location__link" :href="location.href" :title="$t('links.details', { location: location.name })" @click="select">
          <div class="si-location__name">{{ location.name }}</div>
          <div v-if="location.name2">{{ location.name2 }}</div>

          <location-address class="si-location__address" :id="id"/>
        </a>

        <location-contact :id="id"/>

      </div>

      <div class="si-location__offering" v-if="listView">
        <p>{{ location.offeringDescription }}</p>
      </div>

      <div class="si-location__actions">
        <location-watchlist :id="id"/>
        <a class="si-location__details" :href="location.href" :title="$t('links.details', { location: location.name })" @click="select">{{ $t('links.details', { location: location.name }) }}</a>
      </div>
  </div>

  <location-template :template="location.template" v-else/>

</template>

<script>
import LocationAddress from './LocationAddress';
import LocationContact from './LocationContact';
import LocationWatchlist from './LocationWatchlist';
import updateQuery from '../../helpers/updateQuery';
import LocationTemplate from './LocationTemplate.vue';

export default {
  components: { LocationTemplate, LocationAddress, LocationContact, LocationWatchlist },

  props: {
    id: Number,
  },

  computed: {
    listView: (vm) => vm.$route.query.view === 'list' && !vm.$screen.isMobile,
    highlight: (vm) => vm.$store.locations.highlight,
    location: (vm) => vm.$store.locations.all?.find(l => Number(l.id) === Number(vm.id)),
  },

  methods: {
    mouseenter () {
      this.$store.locations.highlight = this.id;
    },

    mouseleave () {
      this.$store.locations.highlight = null;
    },

    select (event) {
      event.preventDefault();

      if (this.listView || this.$screen.isMobile) {
        window.open(this.location.href);
        return;
      }

      this.$router.push(updateQuery(this.$route, 'view', this.id));
    },
  },

  watch: {
    id () {
      this.$store.locations.load(this.id);
    }
  },

  mounted () {
    this.$store.locations.load(this.id);
  },
}
</script>
