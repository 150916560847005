<template>
  <div class="si-location__contact" :class="{ 'si-location__contact--collapsed': !expanded, 'si-location__contact--expanded': expanded }">
    <a class="si-location__contact-link si-location__contact-link--website" :href="absoluteUrl(location.website)" target="_blank" rel="noreferrer noopener" :title="$t('links.website')" v-if="location.website && location.website !== '-'">{{ websiteLabel }}</a>
    <template v-if="location.email && location.email !== '-'">
      <a class="si-location__contact-link si-location__contact-link--email" :href="`mailto:${location.email}`" :title="$t('links.email', { email: location.email })" v-if="location.email.includes('@')">{{ location.email }}</a>
      <a class="si-location__contact-link si-location__contact-link--email" :href="absoluteUrl(location.email)" target="_blank" rel="noreferrer noopener" :title="$t('links.emailForm')" v-else>{{ location.email }}</a>
    </template>
    <a class="si-location__contact-link si-location__contact-link--chat" :href="absoluteUrl(location.chat)" target="_blank" rel="noreferrer noopener" :title="$t('links.chat')" v-if="location.chat && location.chat !== '-'">{{ location.chat }}</a>
    <span class="si-location__contact-link si-location__contact-link--phone" v-if="location.phone && location.phone !== '-'">{{ location.phone }}</span>
  </div>
</template>

<script>
export default {
  props: {
    id: Number,
    expanded: Boolean,
  },

  computed: {
    location: (vm) => vm.$store.locations.all?.find(l => Number(l.id) === Number(vm.id)),

    websiteLabel: (vm) => vm.location.website.replace(/^(https?:\/\/)?([^/]+)(.*)$/, '$2'),
  },

  methods: {
    absoluteUrl (value) {
      return /^https?:\/\//.test(value) ? value : `http://${value}`
    }
  }
}
</script>
