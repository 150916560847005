<template>
  <div class="si-select-menu">
    <div class="si-select-menu__overlay" @click="expanded=false" v-if="expanded && $screen.isDesktop"></div>
    <button
        class="si-select-menu__toggle"
        :class="{
          'si-select-menu__toggle--title': title,
          'si-select-menu__toggle--outline': outline && !expanded && modelValue.length,
          'si-select-menu__toggle--expanded': expanded
        }"
        :aria-controls="ariaId" :aria-expanded="expanded ? 'true' : 'false'"
        @click="expanded=!expanded" @keydown.esc.prevent="expanded=false"
    >
      <span class="si-select-menu__title" v-if="title">{{ title }}</span>
      <span class="si-select-menu__content">
        <span class="si-select-menu__value" v-if="modelValue.length">{{ labels.join(', ') }}</span>
        <span :class="{ 'si-select-menu__value': true, 'si-select-menu__value--title': !title }" v-else>{{ placeholder }}</span>
        <span class="si-select-menu__active" aria-hidden="true" v-if="modelValue.length && !outline"></span>
      </span>
    </button>

    <si-overlay
        class="si-select-menu__results" :class="{ 'si-select-menu__results--bifid': columns.length > 1 }"
        :id="ariaId" :title="overlayTitle || title"
        @close="expanded=false" @apply="expanded=false" @revert="revert"
        v-if="expanded"
    >
      <fieldset class="checkbox_container">
        <div v-for="column in columns">
          <span class="si-select-menu__result" v-for="option in column">
            <input :id="`${ariaId}_${option.value}`" type="checkbox" :value="option.value" :disabled="!modelValue.includes(option.value) && count && count[option.value] === 0" :checked="modelValue.includes(option.value)" @change="change">
            <label class="si-select-menu__label" :for="`${ariaId}_${option.value}`">
              {{ option.label }}
              <span class="si-select-menu__count" v-if="count && count[asValue(option.value)] !== undefined">{{ count[asValue(option.value)] }}</span>
            </label>
          </span>
        </div>
      </fieldset>
    </si-overlay>
  </div>
</template>

<script>
import asValue from '../../helpers/asValue';
import randomName from '../../helpers/randomName';
import SiOverlay from './Overlay.vue';

export default {
  components: { SiOverlay },

  props: {
    title: String,
    placeholder: String,
    overlayTitle: String,
    options: Array,
    count: Object,
    modelValue: Array,
    outline: Boolean,
  },

  data: () => ({
    ariaId: randomName('select-menu-'),
    expanded: false,
  }),

  computed: {
    labels: (vm) => vm.modelValue.map(k => vm.options.find(o => o.value === k)?.label),
    columns () {
      const total = this.options.length;

      if (total < 6) {
        return [this.options];
      }

      return [
        this.options.slice(0, Math.ceil(total / 2)),
        this.options.slice(Math.ceil(total / 2))
      ];
    }
  },

  methods: {
    asValue,

    change (event) {
      const input = asValue(event.target.value);
      let value = Array.from(this.modelValue);

      if (event.target.checked) {
        value.push(input);
      } else {
        value = value.filter(v => v !== input);
      }

      this.$emit('update:modelValue', value);
    },

    revert () {
      this.$emit('update:modelValue', []);
      this.expanded = false;
    }
  }
}
</script>

<style lang="scss">
@import "../../../styles/defaults";

.si-select-menu {
  position: relative;

  &__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: map-get($backgrounds, active);
    opacity: .2;
    z-index: 100;
  }

  &__toggle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0 calc(5% + 25px) 0 8%;
    text-align: left;
    background: #fff;
    border: 1px solid map-get($borders, default);
    border-radius: 100px;
    cursor: pointer;

    &--outline {
      border-color: map-get($font-color, gray);
    }

    &:after {
      content: "";
      position: absolute;
      right: 5%;
      top: calc(50% - 6px);
      width: 20px;
      height: 10px;
      background: url("../../../images/select.svg") 0 0/contain no-repeat;
      transition: transform .1s ease-in-out;
      transform-origin: center center;
      transform: rotateZ(-90deg);
    }
  }

  &__title {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
    color: map-get($font-color, placeholder);
  }

  &__content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    font-size: inherit;
  }

  &__value {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &--title {
      color: map-get($font-color, placeholder);
    }
  }

  &__active {
    width: 6px;
    height: 6px;
    margin-left: 4px;
    text-indent: -999em;
    background: map-get($backgrounds, button);
    border-radius: 50%;
    flex-shrink: 0;
  }

  &__result {
    display: block;
    font-size: 17px;
    margin: 0;
    padding: 15px 20px;
    border-bottom: 1px solid map-get($borders, light);

    .checkbox_container > div:last-child > &:last-child {
      border-bottom: none;
    }
  }

  &__count {
    position: relative;
    top: -4px;
    left: 4px;
    font-size: 14px;
  }
}

@include media(tablet, '.si-select-menu') {
  &__title {
    font-size: 15px;
  }

  &__active {
    width: 8px;
    height: 8px;
    margin-left: 8px;
  }
}

@include media(desktop, '.si-select-menu') {
  &__toggle {
    &--title:after {
      margin-top: 12px; // Height of title container
    }
  }

  &__result {
    padding: 15px 25px;
    font-size: 19px;
    border: none;
  }

  &__toggle {
    &:after {
      transform: rotateZ(0deg);
    }

    &--expanded {
      z-index: 102;

      &:after {
        transform: rotateZ(180deg);
      }
    }
  }

  &__results {
    padding-top: 15px;
    padding-bottom: 15px;

    &--bifid {
      width: max-content;
      max-width: 200%;

      .checkbox_container {
        display: flex;
      }

      .si-select-menu__result:last-child {
        border-bottom: none;
      }
    }
  }
}
</style>
