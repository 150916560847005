<template>

  <div class="si-location-details__contact" v-if="details">
    <div class="si-location-details__link">
      <div class="si-location-details__name placeholder placeholder--lines placeholder--headline" :style="`width:${line}%`" v-for="line in lines(nameLength)"/>
      <div class="si-location-details__name2 placeholder placeholder--lines" :style="`width:${line}%`" v-for="line in lines(name2Length)" v-if="name2Length"/>
    </div>

    <div class="si-location-details__address si-location__address" v-if="addressLength">
      <div class="placeholder placeholder--lines" :style="`width:${line}%`" v-for="line in lines(addressLength)"/>
    </div>

    <div class="si-location__contact si-location__contact--expanded">
      <div class="si-location__contact-link" v-if="hasWebsite">
        <div class="placeholder placeholder--block" style="margin-right:5px"></div>
        <div class="placeholder placeholder--lines" style="width:50%"></div>
      </div>
      <div class="si-location__contact-link" v-if="hasEmail">
        <div class="placeholder placeholder--block" style="margin-right:5px"></div>
        <div class="placeholder placeholder--lines" style="width:50%"></div>
      </div>
      <div class="si-location__contact-link" v-if="hasChat">
        <div class="placeholder placeholder--block" style="margin-right:5px"></div>
        <div class="placeholder placeholder--lines" style="width:50%"></div>
      </div>
      <div class="si-location__contact-link" v-if="phoneLength">
        <div class="placeholder placeholder--block" style="margin-right:5px"></div>
        <div class="placeholder placeholder--lines" :style="`width:${line}%`" v-for="line in lines(phoneLength, 25)"/>
      </div>
    </div>
  </div>

  <div class="si-location" :class="{ 'si-location--list': listView }" v-else>
      <div class="si-location__about" :class="{ 'si-location__contact--list': listView }">
        <div class="si-location__name placeholder placeholder--lines placeholder--headline" :style="`width:${line}%`" v-for="line in lines(nameLength)"/>
        <div class="placeholder placeholder--lines" :style="`width:${line}%`" v-for="line in lines(name2Length)" v-if="name2Length"/>

        <div class="si-location__address" v-if="addressLength">
          <div class="placeholder placeholder--lines" :style="`width:${line}%`" v-for="line in lines(addressLength)"/>
        </div>

        <div class="si-location__contact si-location__contact--collapsed">
          <div class="si-location__contact-link placeholder placeholder--block" v-if="hasWebsite"></div>
          <div class="si-location__contact-link placeholder placeholder--block" v-if="hasEmail"></div>
          <div class="si-location__contact-link placeholder placeholder--block" v-if="hasChat"></div>
          <div class="si-location__contact-link placeholder placeholder--lines" style="width:50%"/>
        </div>

      </div>

      <div class="si-location__offering" v-if="listView">
        <div class="placeholder placeholder--lines" :style="`width:${line}%`" v-for="line in lines(offeringLength, 50, 4)"></div>
      </div>

  </div>
</template>

<script>
export default {
  props: {
    template: Array,
    details: Boolean,
  },

  computed: {
    listView: (vm) => !vm.$screen.isMobile && vm.$route.query.view === 'list',

    nameLength: (vm) => vm.template[0],
    name2Length: (vm) => vm.template[1],
    addressLength: (vm) => vm.template[2],
    hasWebsite: (vm) => !!vm.template[3],
    hasEmail: (vm) => !!vm.template[4],
    hasChat: (vm) => !!vm.template[5],
    phoneLength: (vm) => vm.template[6],
    offeringLength: (vm) => vm.template[7],
  },

  methods: {
    lines (chars, perLine = 35, maxLines = 0) {
      const lines = Array(Math.ceil(chars / perLine)).fill(100);

      lines[lines.length - 1] = 100 / perLine * (chars - Math.floor(chars / perLine) * perLine);

      if (maxLines > 0) {
        return lines.slice(0, maxLines + 1);
      }

      return lines;
    }
  }
}
</script>
