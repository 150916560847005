
export default function ($route, key, input = null) {
  const query = Object.assign({}, $route.query);
  const value = Array.isArray(input) ? input.sort().join(',') : input;

  if (query[key] && query[key] === value) {
    return $route;
  }

  delete query[key];

  if (value) {
    query[key] = value;
  }

  if (key !== 'page' && query.page) {
    delete query.page;
  }

  if (key !== 'view' && query.view && query.view !== 'list') {
    delete query.view;
  }

  const m = new Map();
  Object.keys(query).sort().forEach((k) => {
    m.set(k, query[k]);
  });

  return { query: Object.fromEntries(m.entries()) };
}
