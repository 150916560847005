
export default function (callback, timeout = 1000, delay = 500) {
  if ('requestIdleCallback' in Window) {
    const handle = window.requestIdleCallback(callback, { timeout: timeout })
    return () => {
      window.cancelIdleCallback(handle)
    }
  } else {
    const handle = setTimeout(callback, delay)
    return () => {
      clearTimeout(handle)
    }
  }
}
