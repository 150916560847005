<template>
  <div class="si-toggle-view" :class="{ 'si-toggle-view--list': listView }">
    <button class="si-toggle-view__link si-toggle-view__link--map" :title="$t('view.mapTitle')" @click.prevent="setView()" v-if="listView">
      <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 43 43">
        <g fill="none" stroke-width="1.5"><path d="M11 15.556v14.51l6.348-3.628 7.255 3.628 6.348-3.628v-14.51l-6.348 3.627-7.255-3.627Z"/><path d="M17.348 11.928v14.6" stroke-linejoin="round"/><path d="M24.603 15.437v14.629" stroke-linejoin="round"/></g>
      </svg>
      <span class="si-toggle-view__hint">{{ $t('view.map') }}</span>
    </button>
    <button class="si-toggle-view__link si-toggle-view__link--list" :title="$t('view.listTitle')" @click.prevent="setView('list')" v-else>
      <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 43 43">
        <g fill="none" stroke-linejoin="round" stroke-width="1.5"><path d="M12.499 21.5h18"/><path d="M12.499 15.065h18"/><path d="M12.499 27.935h8.711"/></g>
      </svg>
      <span class="si-toggle-view__hint">{{ $t('view.list') }}</span>
    </button>
  </div>
</template>

<script>
import updateQuery from '../../helpers/updateQuery';

export default {
  computed: {
    listView: (vm) => vm.$route.query.view === 'list',
  },

  methods: {
    setView (value = null) {
      this.$router.push(updateQuery(this.$route, 'view', value));
    }
  }
}
</script>

<style lang="scss">
@import "../../../styles/defaults";

.si-toggle-view {
  position: fixed;
  bottom: 60px;
  left: 50vw;
  transform: translateX(-50%);
  transition: bottom .3s ease-in-out;
  z-index: 10;

  &--list {
    bottom: calc(70vh + 10px);
    bottom: calc((var(--vh, 1vh) * 70) + 10px);
  }

  &__link {
    position: relative;
    display: flex;
    align-items: center;
    height: 38px;
    padding: 0 15px 0 5px;
    color: #fff;
    background: map-get($font-color, link);
    border: 1px solid map-get($font-color, link);
    border-radius: 43px;
    box-shadow: 0 2px 6px #00000029;
    cursor: pointer;

    svg {
      stroke: #fff;
    }

    &:active {
      color: #fff;
      background: map-get($font-color, active);
      border-color: map-get($font-color, active);

      svg {
        stroke: #fff;
      }
    }
  }

  &__hint {
    font-size: 14px;
    height: 25px;
  }
}

@include media(tablet, '.si-toggle-view') {
  position: static;
  bottom: auto;
  left: auto;
  transform: none;

  &__link {
    height: 43px;
    background: #fff;
    color: map-get($font-color, link);
    box-shadow: none;

    svg {
      stroke: map-get($font-color, link);
    }

    &:hover {
      color: #fff;
      background: map-get($font-color, hover);
      border-color: map-get($font-color, hover);

      svg {
        stroke: #fff;
      }
    }
  }

  &__hint {
    font-size: 16px;
    height: auto;
  }
}
</style>
