<template>
  <select-menu
      :placeholder="$t('filters.specializations')"
      :overlay-title="$t('filters.specializations')"
      :options="options"
      :count="count"
      v-model="selection"
  />
</template>

<script>
import updateQuery from '../../helpers/updateQuery';
import SelectMenu from '../Fragments/SelectMenu.vue';
import idle from '../../helpers/idle';
import filter from '../../helpers/filter';

export default {
  inject: ['filters', 'activeFilters'],
  components: { SelectMenu },

  data: () => ({
    selection: [],

    debounceCount: null,
    count: {},
  }),

  computed: {
    query: (vm) => vm.$route.query,
    options: (vm) => vm.filters.specializations,
  },

  methods: {
    loadQuery () {
      if (this.query.specializations) {
        const values = this.query.specializations.split(',').map(id => Number(id));
        const selection = this.options.map(o => Number(o.value)).filter(k => values.includes(k));

        if (this.selection.length !== selection.length) {
          this.selection = selection;
        }
      } else if (this.selection.length) {
        this.selection = [];
      }
    },

    revert () {
      this.selection = [];
      this.expanded = false;
    },

    updateCounts () {
      if (this.debounceCount) {
        this.debounceCount();
      }

      this.debounceCount = idle(() => {
        const counts = {};
        this.options.forEach((option) => {
          const filters = Object.assign({}, this.activeFilters);

          delete filters.specializations;
          filters.specializations = [option.value];

          counts[option.value] = filter(this.$store.locations.all, filters, this.$store.place).length;
        });

        this.count = counts;
      });
    }
  },

  watch: {
    selection () {
      this.$router.push(updateQuery(this.$route, 'specializations', this.selection));
    },

    query () {
      this.loadQuery();
    },

    activeFilters () {
      this.updateCounts();
    }
  },

  mounted () {
    this.loadQuery();
    this.updateCounts();
  }
}
</script>
