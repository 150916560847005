
export function captureError (message, extra = undefined) {
    import(/* webpackChunkName: "sentry" */'@sentry/vue').then(async (Sentry) => {
        Sentry.captureEvent({ message, level: 'error', extra });
    }).catch(() => { window.location.reload(true) });
}

export function captureWarning (message, extra = undefined) {
    import(/* webpackChunkName: "sentry" */'@sentry/vue').then(async (Sentry) => {
        Sentry.captureEvent({ message, level: 'warning', extra });
    }).catch(() => { window.location.reload(true) });
}

export function captureMessage (message) {
    import(/* webpackChunkName: "sentry" */'@sentry/vue').then(async (Sentry) => {
        Sentry.captureMessage(message);
    }).catch(() => { window.location.reload(true) });
}

export function loadSentry (app) {
    if (!window.SENTRY_ENV) {
        return;
    }

    import(/* webpackChunkName: "sentry" */'@sentry/vue').then(async (Sentry) => {
        const { Integrations } = await import(/* webpackChunkName: "sentry" */'@sentry/tracing');
        const { Replay } = await import(/* webpackChunkName: "sentry" */'@sentry/browser');

        Sentry.init({
            app,
            dsn: 'https://df67a9c7dd7e447fbc72ad5a03f93964@o27120.ingest.sentry.io/6550186',
            environment: window.SENTRY_ENV,
            logErrors: true,
            debug: false,

            integrations: [
                new Integrations.BrowserTracing(),
                new Replay({
                    blockAllMedia: false,
                    maskAllInputs: false,
                    maskAllText: false,
                    maskTextSelector: '.mod_login,.mod_changePassword,.mod_registrationNotificationCenter,.mod_lostPasswordNotificationCenter'
                })
            ],

            replaysSessionSampleRate: 0.05,
            replaysOnErrorSampleRate: 1.0,
            tracesSampleRate: 0.1,
            tracingOptions: {
                trackComponents: true
            },
        });

        if (window.SENTRY_USER) {
            Sentry.setUser({
                id: window.SENTRY_USER
            });
        }
    }).catch(() => { window.location.reload(true) });
}
