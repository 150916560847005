<template>
  <div class="si-sorting">
    <button
        class="si-sorting__toggle" :class="{ 'si-sorting__toggle--expanded': expanded }"
        :aria-controls="ariaId" :aria-expanded="expanded ? 'true' : 'false'"
        @click="expanded=!expanded" @keydown.esc.prevent="expanded=false"
    >{{ $t('sorting.title') }}</button>
    <div class="si-sorting__overlay" @click.stop="expanded=false" v-if="expanded"></div>
    <ul ref="options" class="si-sorting__options" :id="ariaId" v-if="expanded">

      <li
          class="si-sorting__option" :class="{ 'si-sorting__option--active': $store.sortBy === 'place'}"
          tabindex="0"
          @click="select('place', true)"
          @mouseenter="focus = null" @focus="focus = 0" @hover="focus = 0;this.focus()"
          @keydown.up.stop="focusUp" @keydown.down.stop="focusDown"
          @keyup.enter="select('place', true)" @keyup.space="select('place', true)"
          @keydown.esc.prevent="close"
          v-if="$store.place"
      >{{ $t(`sorting.place`) }}</li>

      <template v-for="(property, i1) in ['city', 'name', 'canton']">
        <template v-for="(asc, i2) in [true, false]">
          <li
              class="si-sorting__option" :class="{ 'si-sorting__option--active': $store.sortBy === property && $store.sortAsc === asc}"
              tabindex="0"
              @click="select(property, asc)"
              @mouseenter="focus = null" @focus="focus = i1 * (i2 + 1) - ($store.place ? 0 : 1)" @hover="focus = i1 * (i2 + 1) - ($store.place ? 0 : 1);this.focus()"
              @keydown.up.stop="focusUp" @keydown.down.stop="focusDown"
              @keyup.enter="select(property, asc)" @keyup.space="select(property, asc)"
              @keydown.esc.prevent="close"
          >{{ $t(`sorting.${property}${asc ? 'ASC' : 'DESC'}`) }}</li>
        </template>
      </template>
    </ul>
  </div>
</template>

<script>
import randomName from '../../helpers/randomName';
import updateQuery from '../../helpers/updateQuery';

export default {
  data: () => ({
    ariaId: randomName('sorting-'),
    expanded: false,
    focus: null,
  }),

  methods: {
    select (field, asc) {
      this.$store.sortBy = field;
      this.$store.sortAsc = asc;
      updateQuery(this.$route, 'sort', `${asc ? '' : '!'}${field}`)

      this.expanded = false;
    },

    close () {
      this.expanded = false;
      this.focus = null;
    },

    focusFirst () {
      this.focus = 0;
      Array.from(this.$refs.options.children)[this.focus].focus();
    },

    focusLast () {
      this.focus = 5
      Array.from(this.$refs.options.children)[this.focus].focus();
    },

    focusUp () {
      if (this.focus === null || this.focus <= 0) {
        this.focus = 5;
      } else {
        this.focus--;
      }

      Array.from(this.$refs.options.children)[this.focus].focus();
    },

    focusDown () {
      if (this.focus === null || this.focus >= 5) {
        this.focus = 0;
      } else {
        this.focus++;
      }

      Array.from(this.$refs.options.children)[this.focus].focus();
    }
  },

  mounted () {
    const sort = this.$route.query.sort;

    if (sort) {
      const asc = sort.substring(0, 1) !== '!';
      const property = asc ? sort : sort.substring(1);

      if (this.$store.sortAsc !== asc || this.$store.sortBy !== property) {
        this.$store.sortBy = property
        this.$store.sortAsc = asc
      }
    }
  }
}
</script>

<style lang="scss">
@import "../../../styles/defaults";

.si-sorting {
  position: relative;

  &__toggle {
    position: relative;
    padding: 3px 25px 4px 10px;
    line-height: 16px;
    background: none;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color .1s ease-in-out;

    &:after {
      content: "";
      position: absolute;
      top: 8px;
      right: 5px;
      width: 15px;
      height: 8px;
      background: url("../../../images/sorting.svg") center center/contain no-repeat;
    }

    &:hover,
    &--expanded {
      background: #EAEAEA;
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
  }

  &__options {
    position: absolute;
    top: 30px;
    background: #FFF;
    box-shadow: 0 3px 6px #0000001A;
    margin: 0;
    padding: 0;
    list-style-type: none;
    z-index: 102;
  }

  &__option {
    margin: 0;
    padding: 10px 20px;
    outline: none;
    cursor: pointer;
    white-space: nowrap;
    line-height: 22px;

    &--focus,
    &:hover,
    &:focus {
      background: #EAEAEA;
    }

    &--active {
      background: map-get($backgrounds, button) !important;
      color: #fff;
    }
  }
}

</style>
