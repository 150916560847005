<template>
  <div class="si-location-details" v-if="location" v-touch:swipe.right="back">
    <div class="si-location-details__actions">
      <button type="button" class="si-location-details__back" :title="$t('details.back')" @click="back"></button>
      <location-watchlist :id="id" v-if="loaded"/>
    </div>

    <div class="si-location-details__contact" v-if="loaded">
      <a class="si-location-details__link" :href="location.href" :title="$t('links.details', { location: location.name })" @click="select">
        <div class="si-location-details__name">{{ location.name }}</div>
        <div class="si-location-details__name2" v-if="location.name2">{{ location.name2 }}</div>
        <location-address class="si-location-details__address" :id="id"/>
      </a>

      <location-contact expanded :id="id"/>
    </div>
    <location-template details :template="location.template" v-else-if="location"/>

    <div v-if="loaded">
      <h2 class="si-location-details__title">{{ $t('details.languages', location.languages.length) }}</h2>
      <location-languages inline :id="id"/>

      <h2 class="si-location-details__title">{{ $t('details.offerings') }}</h2>
      <p class="si-location-details__description">{{ location.offeringDescription }}</p>
      <ul class="si-location-details__specializations" v-if="location.specializations.length">
        <li v-for="label in specialization">{{ label }}</li>
      </ul>
    </div>

    <a class="si-location-details__details" :href="location.href" :title="$t('links.details', { location: location.name })" @click="select" v-if="loaded">{{ $t('links.details', { location: location.name }) }}</a>
  </div>
</template>

<script>
import updateQuery from '../../helpers/updateQuery';
import LocationWatchlist from '../Fragments/LocationWatchlist';
import LocationContact from '../Fragments/LocationContact';
import LocationAddress from '../Fragments/LocationAddress';
import LocationLanguages from '../Fragments/LocationLanguages';
import LocationTemplate from '../Fragments/LocationTemplate.vue';

export default {
  components: { LocationLanguages, LocationAddress, LocationContact, LocationWatchlist, LocationTemplate },
  inject: ['filters'],

  props: {
    id: Number,
  },

  computed: {
    location: (vm) => vm.$store.locations.all?.find(l => Number(l.id) === Number(vm.id)),
    loaded: (vm) => !!vm.location.name,

    specialization: (vm) => vm.location.specializations.map((id) => vm.filters.specializations.find(o => o.value === id).label),
  },

  methods: {
    back () {
      this.$router.push(updateQuery(this.$route, 'view'));
    },

    select (event) {
      event.preventDefault();
      window.open(this.location.href);
    }
  },

  watch: {
    id () {
      this.$store.locations.load(this.id);
    }
  },

  mounted () {
    this.$store.locations.load(this.id);
  },
}
</script>

<style lang="scss">
@import "../../../styles/defaults";

.si-location-details {
  overflow: hidden;
  width: 370px;
  min-height: calc(100vh - 185px);
  min-height: calc((var(--vh, 1vh) * 100) - 185px);
  margin: 0 10px;
  padding: 25px 20px 25px 30px;
  font-size: 16px;
  line-height: 22px;
  border-radius: 3px;
  border: 1px solid #EAEAEA;

  &__actions {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    margin-bottom: 50px;
  }

  &__back {
    width: 25px;
    height: 25px;
    background: url("../../../images/link-forward--infodrog.svg") -75px 0/125px 25px no-repeat;
    border: none;
    cursor: pointer;
    transform: rotateZ(180deg);

    &:hover {
      background-position: -25px 0;
    }

    &:active {
      background-position: -50px 0;
    }
  }

  &__link {
    line-height: 29px;
  }

  &__name {
    font-size: 19px;
    color: map-get($font-color, link);
    font-weight: map-get($font-weight, medium);

    .si-location-details__link:hover & {
      color: map-get($font-color, hover);
    }

    .si-location-details__link:active & {
      color: map-get($font-color, active);
    }
  }

  &__name2 {
    font-size: 19px;
    //font-weight: map-get($font-weight, medium);
  }

  &__address {
    font-size: 19px;
    margin: 10px 0 25px;
    color: map-get($font-color, gray);
  }

  &__title {
    margin: 35px 0 10px;
    font-size: 14px;
    line-height: 23px;
    text-transform: uppercase;
    color: map-get($font-color, gray);
  }

  &__description {
    margin-top: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
  }

  &__specializations {
    display: flex;
    flex-wrap: wrap;
    margin: 2em 0 0;
    padding: 0;
    list-style-type: none;

    li {
      margin: 0 5px 0 0;
      padding: 0;

      &:after {
        content: ",";
      }

      &:last-child:after {
        content: none;
      }
    }
  }

  &__details {
    float: right;
    margin-top: 20px;
    @include icon(details);
  }
}

@include media(desktop, '.si-location-details') {
  width: 380px;
  margin-left: 0;
}
</style>
