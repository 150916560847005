<template>
  <div class="si-swisstopo-search">
    <div class="si-swisstopo-search__overlay" @click="close" v-if="results && $screen.isDesktop"></div>

    <button
        type="button"
        class="si-swisstopo-search__toggle"
        :class="{
          'si-swisstopo-search__toggle--title': title,
          'si-swisstopo-search__toggle--outline': outline && modelValue
        }"
        @click="expand"
        v-if="!$screen.isDesktop"
    >
      <span class="si-swisstopo-search__title" v-if="title">{{ title }}</span>
      <span class="si-swisstopo-search__content">
        <span class="si-swisstopo-search__value" v-if="modelValue">{{ modelValue.label }}</span>
        <span :class="{ 'si-swisstopo-search__value': true, 'si-swisstopo-search__value--title': !title }" v-else>{{ placeholder }}</span>
        <span class="si-swisstopo-search__active" aria-hidden="true" v-if="modelValue && !outline"></span>
      </span>
    </button>

    <div
        class="si-swisstopo-search__input"
        :class="{
          'si-swisstopo-search__input--focus': searching,
          'si-swisstopo-search__input--expanded': results,
          'si-swisstopo-search__input--outline': outline && modelValue
        }"
        @click="$refs.search.focus()"
        v-else
    >
      <span class="si-swisstopo-search__title" v-if="title">{{ title }}</span>
      <input
          type="search" ref="search"
          :placeholder="placeholder"
          @focus="searching=true" @blur="searching=false"
          @keyup="startSearch"
          @keydown.down.prevent="focusFirst" @keydown.up.prevent="focusLast"
          @keydown.esc.prevent="close"
          v-model="searchText"
      >
      <button type="button" class="si-swisstopo-search__locate" :class="{ 'si-swisstopo-search__locate--title': title, 'si-swisstopo-search__locate--loading': locating }" :title="$t('place.locate')" @click="locate" v-if="canLocate && !searchText">{{ $t('place.locate') }}</button>
      <button type="button" class="si-swisstopo-search__remove" :class="{ 'si-swisstopo-search__remove--title': title }" :title="$t('place.clear')" @click="remove" v-else-if="searchText || results">{{ $t('place.clear') }}</button>
    </div>

    <si-overlay
        :title="overlayTitle || title" max-height="400"
        @close="close" @apply="close" @revert="remove"
        v-if="expanded || results"
    >
      <template #control v-if="!$screen.isDesktop">
        <div class="si-swisstopo-search__input">
          <input
              type="search" ref="search"
              :placeholder="placeholder"
              @keyup="startSearch"
              v-model="searchText"
          >
          <button type="button" class="si-swisstopo-search__locate" :class="{ 'si-swisstopo-search__locate--loading': locating }" :title="$t('place.locate')" @click="locate" v-if="canLocate && !searchText">{{ $t('place.locate') }}</button>
          <button type="button" class="si-swisstopo-search__remove" :title="$t('place.clear')" @click="expand" v-else-if="searchText || results">{{ $t('place.clear') }}</button>
        </div>
      </template>

      <ul ref="results" class="si-swisstopo-search__results" v-if="results">
        <template v-for="(result, index) in sortedResults" v-if="results.length">
          <li
              class="si-swisstopo-search__result"
              tabindex="0"
              @click="select(result)"
              @mouseenter="focus = null" @focus="focus = index" @hover="focus = index;this.focus()"
              @keydown.up.prevent="focusUp" @keydown.down.prevent="focusDown"
              @keyup.enter="select(result)" @keyup.space="select(result)"
              @keydown.esc.prevent="close"
              v-if="result"
          >
            <template v-if="result && result.label">{{ result.label }}</template>
            <div class="si-swisstopo-search__placeholder placeholder" v-else></div>
          </li>
        </template>
        <li class="si-swisstopo-search__empty" v-else>
          {{ $t('place.empty') }}
        </li>
      </ul>
    </si-overlay>
  </div>
</template>

<script>
import { captureError } from '../../helpers/sentry';
import SiOverlay from './Overlay.vue';

let debounce = undefined;

export default {
  components: { SiOverlay },

  props: {
    title: String,
    placeholder: String,
    overlayTitle: String,
    modelValue: Object,
    outline: Boolean,
    cantons: Object,
  },

  data: () => ({
    searchText: '',
    resultText: '',
    results: null,
    focus: null,
    searching: false,
    expanded: false,

    locating: false,

    currentSearch: null,
  }),

  computed: {
    canLocate: () => 'geolocation' in navigator,

    sortedResults: (vm) => vm.results ? Array.from(vm.results).sort((a, b) => {
      if (Number(a.weight) !== Number(b.weight)) {
        return b.weight - a.weight
      }

      if (!a.postal) {
        return 1;
      }

      if (!b.postal) {
        return -1;
      }

      return a.postal - b.postal;
    }) : []
  },

  methods: {
    expand () {
      this.searchText = '';
      this.resultText = '';
      this.results = null;
      this.expanded = true;

      this.$nextTick(() => {
        this.$refs.search.focus();
      })
    },

    startSearch () {
      if (!this.searchText) {
        this.results = null;
        this.resultText = '';
        return;
      }

      if (this.searchText === this.resultText) {
        return;
      }

      // Only start searching text results after three key strokes
      if (isNaN(this.searchText) && this.searchText.length < 3) {
        return;
      }

      clearTimeout(debounce);
      debounce = setTimeout(() => {
        this.search(this.searchText);
      }, 300);
    },

    async search (searchText) {
      if (this.currentSearch) {
        this.currentSearch.abort();
      }

      let signal;
      if ("AbortController" in window) {
        this.currentSearch = new AbortController();
        signal = this.currentSearch.signal;
      }

      this.results = [{}];
      this.focus = null;
      this.resultText = searchText;
      let results;

      try {
        results = (await (await fetch(`https://api3.geo.admin.ch/rest/services/api/SearchServer?features=ch.swisstopo-vd.ortschaftenverzeichnis_plz,ch.swisstopo.swissboundaries3d-kanton-flaeche.fill&type=featuresearch&lang=${ this.$i18n.locale }&searchText=${ searchText }`, { signal })).json()).results;

        if (!results?.length) {
          results = (await (await fetch(`https://api3.geo.admin.ch/rest/services/api/SearchServer?type=locations&origins=zipcode,gg25,kantone&lang=${ this.$i18n.locale }&searchText=${ searchText }`, { signal })).json()).results;
        }
      } catch (err) {
        if (err.name === 'AbortError') {
          return;
        }

        throw err;
      }

      // Somehow there can be null-value results, so we filter them out
      results = Array.from(results || []).filter((r) => !!r);

      if (!results.length) {
        if (/\d{4}/.test(searchText)) {
          return this.search(searchText.substring(0, 3));
        }

        this.results = [];
        return;
      }

      this.results = results.map((result) => ({
        lat: result.attrs.lat,
        lng: result.attrs.lon,
        weight: result.weight + (result.attrs?.rank || 0),
        postal: 0,
        label: '',
      }));

      // noinspection ES6MissingAwait
      results.forEach(async (result, index) => {
        let data = {};

        try {
          switch (result.attrs.layer || result.attrs.origin) {
            case 'ch.swisstopo.swissboundaries3d-kanton-flaeche.fill':
            case 'kantone':
              data = await this.fetchCanton(result.attrs.lat, result.attrs.lon);
              break;

            case 'gg25':
              let place;

              try {
                const nameJSON = (await (await fetch(`https://api3.geo.admin.ch/rest/services/api/MapServer/identify?geometryType=esriGeometryPoint&geometry=${ result.attrs.lon },${ result.attrs.lat }&sr=4326&imageDisplay=0,0,0&mapExtent=0,0,0,0&tolerance=0&layers=all:ch.swisstopo.swissboundaries3d-gemeinde-flaeche.fill`, { signal })).json());
                const name = nameJSON.results[0].attributes.gemname;
                const placeJSON = (await (await fetch(`https://api3.geo.admin.ch/rest/services/api/SearchServer?features=ch.swisstopo-vd.ortschaftenverzeichnis_plz&type=featuresearch&lang=${ this.$i18n.locale }&searchText=${ name }`, { signal })).json());
                place = placeJSON.results[0];
              } catch (err) {
                if (err.name === 'AbortError') {
                  return;
                }

                throw err;
              }

              if (!place) {
                delete this.results[index];
                captureError('Unable to find Swisstopo gg25 place', {
                  text: this.resultText,
                  result,
                  name: nameJSON.results,
                  place: placeJSON.results,
                });
                return;
              }

              data = await this.fetchPlace(place.attrs.lat, place.attrs.lon)
              break;

            default:
              data = await this.fetchPlace(result.attrs.lat, result.attrs.lon)
              break;
          }
        } catch (err) {
          if (err.name === 'AbortError') {
            return;
          }

          if (this.results) {
            delete this.results[index];
          }
        }

        // Cancel after await if a new search was started
        if (!this.results) {
          return;
        }

        this.results[index] = Object.assign({}, this.results[index], data);
      });
    },

    select (result) {
      // Do not "submit" a not-loaded result
      if (!result?.label) {
        return;
      }

      this.searchText = result.label;
      this.results = null;
      this.expanded = false;

      this.$emit('update:modelValue', result);
    },

    locate () {
      if (this.locating) {
        return;
      }

      this.locating = true;

      navigator.geolocation.getCurrentPosition(async (position) => {
        const result = await this.fetchPlace(position.coords.latitude, position.coords.longitude);
        this.locating = false;
        this.select(result);
      }, () => {
        this.locating = false;
        alert(this.$t('view.locateError'));
      });
    },

    remove () {
      if (this.currentSearch) {
        this.currentSearch.abort();
      }

      this.searchText = '';
      this.results = null;
      this.expanded = false;
      this.$emit('update:modelValue', null);
    },

    close () {
      if (this.currentSearch) {
        this.currentSearch.abort();
      }

      this.searchText = '';
      this.resultText = '';
      this.results = null;
      this.expanded = false;
      this.focus = null;
      this.currentSearch = null;

      if (this.$refs.search) {
        this.$refs.search.focus();
      }
    },

    focusFirst () {
      if (!this.results?.length) {
        return;
      }

      this.focus = 0;
      Array.from(this.$refs.results.children)[this.focus].focus();
    },

    focusLast () {
      if (!this.results?.length) {
        return;
      }

      this.focus = this.results.length - 1
      Array.from(this.$refs.results.children)[this.focus].focus();
    },

    focusUp () {
      if (!this.results?.length) {
        return;
      }

      if (this.focus === null || this.focus <= 0) {
        this.focus = this.results.length - 1;
      } else {
        this.focus--;
      }

      Array.from(this.$refs.results.children)[this.focus].focus();
    },

    focusDown () {
      if (!this.results?.length) {
        return;
      }

      if (this.focus === null || this.focus >= this.results.length - 1) {
        this.focus = 0;
      } else {
        this.focus++;
      }

      Array.from(this.$refs.results.children)[this.focus].focus();
    },

    async fetchPlace (lat, lng) {
      const data = { lat, lng };
      const signal = this.currentSearch?.signal;

      try {
        const results = (await (await fetch(`https://api3.geo.admin.ch/rest/services/api/MapServer/identify?geometryType=esriGeometryPoint&geometry=${ lng },${ lat }&imageDisplay=0,0,0&mapExtent=0,0,0,0&tolerance=0&layers=all:ch.swisstopo-vd.ortschaftenverzeichnis_plz&sr=4326&lang=${ this.$i18n.locale }`, { signal })).json()).results;
        data.bounds = results[0].bbox;
        data.label = `${ results[0].attributes.plz } ${ results[0].attributes.langtext }`;
        data.postal = results[0].attributes.plz;
      } catch (err) {
        if (err.name === 'AbortError') {
          return;
        }

        throw err;
      }

      return data;
    },

    async fetchCanton (lat, lng) {
      const data = { lat, lng };
      const signal = this.currentSearch?.signal;

      try {
        const results = (await (await fetch(`https://api3.geo.admin.ch/rest/services/api/MapServer/identify?geometryType=esriGeometryPoint&geometry=${ lng },${ lat }&imageDisplay=0,0,0&mapExtent=0,0,0,0&tolerance=0&layers=all:ch.swisstopo.swissboundaries3d-kanton-flaeche.fill&sr=4326`, { signal })).json()).results;
        data.bounds = results[0].bbox;
        data.canton = results[0].attributes.ak;
        data.label = this.cantons && this.cantons[results[0].attributes.ak] || results[0].attributes.name;
      } catch (err) {
        if (err.name === 'AbortError') {
          return;
        }

        throw err;
      }

      return data;
    }
  },

  watch: {
    modelValue () {
      this.searchText = this.modelValue?.label || '';
    }
  }
}
</script>

<style lang="scss">
@import "../../../styles/defaults";

.si-swisstopo-search {
  position: relative;

  input {
    width: 100%;
    background: none;
    border: none;
    outline: none;
  }

  &__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: map-get($backgrounds, active);
    opacity: .2;
    z-index: 100;
  }

  &__toggle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0 calc(5% + 25px) 0 8%;
    text-align: left;
    background: #FFF;
    border: 1px solid map-get($borders, default);
    border-radius: 100px;
    cursor: pointer;

    &--outline {
      border-color: map-get($font-color, gray);
    }

    &:after {
      content: "";
      position: absolute;
      right: 5%;
      top: calc(50% - 6px);
      width: 20px;
      height: 10px;
      background: url("../../../images/select.svg") 0 0/contain no-repeat;
      transition: transform .1s ease-in-out;
      transform-origin: center center;
      transform: rotateZ(-90deg);
    }
  }

  &__title {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
    color: map-get($font-color, placeholder);

    + input::placeholder {
      color: map-get($font-color, default);
    }
  }

  &__content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    font-size: inherit;
  }

  &__value {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &--title {
      color: map-get($font-color, placeholder);
    }
  }

  &__active {
    width: 6px;
    height: 6px;
    margin-left: 4px;
    text-indent: -999em;
    background: map-get($backgrounds, button);
    border-radius: 50%;
    flex-shrink: 0;
  }

  &__input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 25px;
    padding: 0 calc(3% + 40px) 0 10%;
    background: #FFF;
    border: 1px solid map-get($borders, default);
    border-radius: 100px;

    &--focus {
      border-color: map-get($borders, focus);
    }

    &--outline {
      border-color: map-get($font-color, gray);
    }

    &--expanded {
      z-index: 102;
    }

    .si-overlay & {
      height: 50px;
      font-size: 17px;
    }
  }

  &__locate,
  &__remove {
    position: absolute;
    top: calc(50% - 18px);
    right: 3%;
    width: 35px;
    height: 35px;
    border: none;
    text-indent: -999em;
    cursor: pointer;

    &--title {
      margin-top: 12px;
      right: 5%;
    }
  }

  &__locate {
    background: url("../../../images/locate.svg") center center/28px 28px no-repeat;

    &--loading {
      background: none;

      &:after {
        content: "";
        position: absolute;
        left: 4px;
        top: 4px;
        display: block;
        height: 28px;
        width: 28px;
        border: 2px solid map-get($borders, default);
        border-top-color: map-get($borders, main);
        border-radius: 50%;
        animation: spinner 800ms linear infinite;
      }

      @keyframes spinner {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }
  }

  &__remove {
    background: url("../../../images/close.svg") center center no-repeat;
  }

  &__results {
    margin: 0;
    padding: 5px 0;
    list-style-type: none;
  }

  &__result {
    font-size: 19px;
    margin: 0;
    padding: 15px 25px;
    outline: none;
    cursor: pointer;

    &--focus,
    &:hover,
    &:focus {
      background: map-get($backgrounds, service);
    }
  }

  &__placeholder {
    display: flex;
    align-items: center;
    height: 30px;
  }

  &__empty {
    font-size: 19px;
    margin: 0;
    padding: 15px 25px;
  }
}

@include media(tablet, '.si-swisstopo-search') {
  &__title {
    font-size: 15px;
  }

  &__active {
    width: 8px;
    height: 8px;
    margin-left: 8px;
  }
}

@include media(desktop, '.si-swisstopo-search') {
  &__toggle {
    &--title:after {
      margin-top: 12px; // Height of title container
    }
  }

  &__input {
    margin: 0;
  }
}
</style>
