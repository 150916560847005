<template>
  <main class="si-list-view">
    <div class="si-list-view__list">
      <location :id="location.id" v-for="location in paged"/>
      <p class="si-list-view__empty" v-if="!$store.locations.ready">{{ $t('view.loading') }}</p>
      <p class="si-list-view__empty" v-else-if="pages === 0">{{ $t('view.empty') }}</p>
    </div>
    <v-pagination v-model="page" :pages="pages" hide-first-button hide-last-button v-if="pages > 0"/>
  </main>
</template>

<script>
import updateQuery from '../../helpers/updateQuery';
import VPagination from "@hennge/vue3-pagination";
import Location from '../Fragments/Location';
import sort from '../../helpers/sort';

const perPage = 10;

export default {
  components: { Location, VPagination },

  inject: ['sorting'],

  computed: {
    page: {
      get () {
        return Number(this.$route.query.page || 1);
      },

      set (value) {
        this.$router.push(updateQuery(this.$route, 'page', value));
      },
    },

    locations: (vm) => sort(vm.$store.locations.filtered, vm.$store.sortBy, vm.$store.sortAsc, vm.sorting, vm.$store.place),
    paged: (vm) => vm.locations.slice((vm.page - 1) * perPage, vm.page * perPage),
    pages: (vm) => Math.ceil(vm.locations.length / perPage),
  },

  methods: {
    validatePage () {
      if (this.pages && this.page > this.pages) {
        this.$nextTick(() => {
          this.$router.push(updateQuery(this.$route, 'page', 1));
        });
      }
    }
  },

  watch: {
    page () {
      this.validatePage();
    },

    pages () {
      this.validatePage();
    },
  },

  mounted () {
    this.validatePage();
  }
}
</script>

<style lang="scss">
@import "../../../styles/defaults";

.si-list-view {

  &__empty {
    text-align: center;
    font-style: italic;
  }

  .Pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 40px 30px 0 0;
    padding: 0;
    list-style-type: none;
  }

  // First / Last Icon
  .PaginationControl {
    display: flex;
    align-items: center;
  }

  .Control {
    position: relative;
    display: block;
    width: 18px;
    height: 18px;
    margin: 0 2px;
    border-radius: 50%;
    fill: map-get($borders, default);
  }

  .Control-active {
    fill: map-get($borders, active);
    cursor: pointer;
  }

  .Control-active:hover {
    background: #eaeaea !important;
  }

  .Page {
    display: flex;
    justify-content: center;
    width: 22px;
    height: 22px;
    margin: 0 2px;
    color: map-get($font-color, gray);
    font-size: 14px;
    line-height: 20px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
  }

  .Page:hover {
    background: #eaeaea !important;
  }

  .Page-active,
  .Page-active:hover {
    color: #fff;
    background: map-get($backgrounds, active) !important;
  }

  .DotsHolder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    margin: 0 2px;
  }

  .Dots {
    width: 8px;
    height: 4px;
    fill: map-get($borders, default);
  }
}
</style>
