<template>
  <span v-if="$store.locations.ready">
    <template v-if="isListView || locationsTotal === locationsOnMap">{{ $t('results.total', locationsTotal) }}</template>
    <template v-else>{{ $t('results.visible', { visible: locationsOnMap, total: locationsTotal }) }}</template>
  </span>
</template>

<script>

export default {
  computed: {
    isListView: (vm) => vm.$route.query.view === 'list' && !vm.$screen.isMobile,
    locationsTotal: (vm) => vm.$store.locations.filtered.length,
    locationsOnMap: (vm) => vm.$store.locations.onMap.length,
  }
}
</script>
