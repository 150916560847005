<template>
  <div class="si-main-filter">
    <si-place class="si-main-filter__control"/>
    <si-specializations class="si-main-filter__control"/>
    <slot/>
  </div>
</template>

<script>
import SiPlace from './Place';
import SiSpecializations from './Specializations';

export default {
  components: { SiPlace, SiSpecializations },
}
</script>

<style lang="scss">
@import "../../../styles/defaults";

.si-main-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 13px 1px 20px;
  animation: .3s ease-in-out unsticky;

  &__control {
    flex-grow: 1;
    width: 120px;
    height: 39px;
    max-width: 200px;
    margin: 10px 5px;
    font-size: 14px;
  }
}

@include media(tablet, '.si-main-filter') {
  &__control {
    flex-grow: 0;
    width: 330px;
    height: 60px;
    max-width: none;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 19px;
  }

  .layout--sticky & {
    position: fixed;
    top: 10px;
    left: 100px;
    right: 260px;
    z-index: 100;
    animation: .3s ease-in-out sticky;

    &__control {
      flex-grow: 1;
      width: 150px;
      height: 50px;
      max-width: 280px;
      font-size: 17px;
    }
  }
}

@include media(desktop, '.si-main-filter') {
  .layout--sticky & {
    left: calc((100vw - 1220px) / 2 + 100px);
    right: calc((100vw - 1220px) / 2 + 320px);
  }
}

</style>
