<template>
  <div class="si-map-drawer" :class="{ 'si-map-drawer--open': open && hasFilteredLocations }">
    <location-details class="si-map-drawer__details" :id="current" v-if="current"/>
    <virtual-scroller class="si-map-drawer__list" :page-mode="!$screen.isMobile" :data-key="'id'" :data-sources="locations" v-else-if="locations.length">
      <template v-slot="{ item }">
        <location class="si-map-drawer__location" :id="item.id"/>
      </template>
    </virtual-scroller>
    <div class="si-map-drawer__empty" v-else>
      <p>{{ $t('view.emptyMap') }}</p>
      <button type="button" @click="$emit('home')">{{ $t('view.zoomHome') }}</button>
    </div>
  </div>
</template>

<script>
import { VirtualScroller } from '@lucas-labs/vue3-vsl';
import LocationDetails from './LocationDetails';
import Location from '../Fragments/Location';
import sort from '../../helpers/sort';

export default {
  components: { Location, LocationDetails, VirtualScroller },
  inject: ['sorting'],

  computed: {
    current: (vm) => Number(vm.$route.query.view),

    hasPlace: (vm) => vm.$store.place,
    isFiltered: (vm) => vm.$store.locations.all.length && vm.$store.locations.filtered.length && vm.$store.locations.all.length !== vm.$store.locations.filtered.length,
    hasFilteredLocations: (vm) => vm.$store.locations.filtered.length > 0,
    view: (vm) => vm.$route.query.view,
    open: (vm) => vm.$screen.isMobile ? vm.view === 'list' : vm.$store.drawer,

    locations: (vm) => sort(vm.$store.locations.onMap, vm.$store.sortBy, vm.$store.sortAsc, vm.sorting, vm.$store.place),
  },

  watch: {
    isFiltered () {
      if (this.isFiltered) {
        this.$store.drawer = true
      }
    },

    hasPlace () {
      if (this.hasPlace) {
        this.$store.drawer = true
      }
    },

    view () {
      if (this.view === 'list') {
        this.$store.locations.highlight = null;
      } else if (this.view) {
        this.$store.drawer = true
      }
    },
  },

  mounted () {
    this.$store.drawer = this.isFiltered || this.view
  }
}
</script>

<style lang="scss">
@import "../../../styles/defaults";

.si-map-drawer {
  position: relative;
  width: 100vw;
  height: calc(70vh - 50px);
  height: calc((var(--vh, 1vh) * 70) - 50px);

  &__list {
    height: 100%;
    overflow-y: auto;
    padding: 0 18px 0 25px;
  }

  &__empty {
    padding: 0 25px;

    p {
      font-style: italic;
    }

    button {
      @include link-forward;
    }
  }
}

@include media(tablet, '.si-map-drawer') {
  width: 0;
  height: calc(100vh - 185px);
  height: calc((var(--vh, 1vh) * 100) - 185px);
  overflow: hidden;
  flex-shrink: 0;
  transition: width .3s ease-in-out;

  &--open {
    width: 390px;
    height: auto;
  }

  &__list {
    width: 390px;
    padding: 0 10px;
  }

  &__empty {
    width: 390px;
    padding: 0 20px;

    p {
      margin-top: 0;
    }
  }
}
</style>
