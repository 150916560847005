
export default function (data, filters, place) {
  return data.filter((location) => {
    for (const key in filters) {
      if (!filters[key].length || !!filters[key].find(v => location[key].includes(v))) {
        continue;
      }

      return false;
    }

    return true;
  });
}
