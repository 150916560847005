<template>
  <div v-if="location.city && location.city !== '-'">
    <template v-if="location.address">{{ location.address }}, </template>
    <template v-if="location.pobox">{{ location.pobox }}, </template>
    {{ location.postal }} {{ location.city }}
  </div>
</template>

<script>
export default {
  props: {
    id: Number,
    expanded: Boolean,
  },

  computed: {
    location: (vm) => vm.$store.locations.all?.find(l => Number(l.id) === Number(vm.id)),
  },
}
</script>
