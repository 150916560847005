<template>
  <div class="si-active-filters">
    <div class="si-active-filters__item" v-if="$store.place">
      {{ $store.place.label }}
      <button class="si-active-filters__button" :title="$t('filters.unset')" @click="unsetPlace()">{{ $t('filters.unset') }}</button>
    </div>
    <div class="si-active-filters__item" v-for="filter in activeFilters">
      {{ filter.label }}
      <button class="si-active-filters__button" :title="$t('filters.unset')" @click="unsetFilter(filter)">{{ $t('filters.unset') }}</button>
    </div>
    <button type="button" class="si-active-filters__item si-active-filters__revert" @click="$emit('revert')" v-if="(activeFilters.length || $store.place) && revert">{{ $t('filters.revert') }} <span class="si-active-filters__revert-icon" aria-hidden="true"></span></button>
  </div>
</template>

<script>
import updateQuery from '../../helpers/updateQuery';

export default {
  inject: ['filters'],

  props: {
    value: Object,
    revert: Boolean,
  },

  computed: {
    activeFilters () {
      const labels = [];

      Object.keys(this.value).forEach((group) => {
        this.value[group].forEach((value) => {
          if (!Array.isArray(this.filters[group])) {
            Object.values(this.filters[group]).forEach((g) => {
              const option = g.find(o => o.value === value);

              if (option) {
                labels.push({
                  group,
                  value,
                  label: option.title || option.label
                });
              }
            });
          } else {
            const option = this.filters[group].find(o => o.value === value);

            labels.push({
              group,
              value,
              label: option.title || option.label
            });
          }
        });
      });

      return labels;
    }
  },

  methods: {
    unsetPlace () {
      this.$router.push(updateQuery(this.$route, 'place'));
    },

    unsetFilter (filter) {
      const values = this.$route.query[filter.group].split(',').filter(v => String(v) !== String(filter.value));

      this.$router.push(updateQuery(this.$route, filter.group, values));
    }
  }
}
</script>

<style lang="scss">
@import "../../../styles/defaults";

.si-active-filters {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin: 15px 0;

  &__item {
    display: flex;
    margin: 2px 8px;
    font-size: 14px;
  }

  &__button {
    @include icon(close);
    margin-left: 8px;
    border: none;
    cursor: pointer;
  }

  &__revert {
    background: none;
    border: none;
    cursor: pointer;

    &-icon {
      @include icon(revert);
      margin-left: 8px;
    }
  }
}

@include media(tablet, '.si-active-filters') {
  max-width: 60%;
  height: 65px;
  margin-top: 0;
  overflow-y: auto;
}
</style>
