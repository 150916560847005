<template>
  <section class="si-app" :class="{ 'si-app--list': $route.query.view === 'list' }">
    <div class="si-app__header" ref="header">
      <main-filters>
        <template v-if="!$screen.isDesktop">
          <button type="button" class="si-app__filter-toggle" :title="$t('filters.show')" @click="expandFilters=true">
            <svg width="16" height="22.098">
              <g stroke="#b50717" stroke-width="1.3">
                <path d="M13.409 22.1V0" fill="none" stroke-linejoin="round"/>
                <path d="M2.999 22.1V0" fill="none" stroke-linejoin="round"/>
                <g transform="translate(0 12.445)" fill="#fff">
                  <circle cx="3" cy="3" r="3" stroke="none"/>
                  <circle cx="3" cy="3" r="2.35" fill="none"/>
                </g>
                <g transform="translate(10 4.058)" fill="#fff">
                  <circle cx="3" cy="3" r="3" stroke="none"/>
                  <circle cx="3" cy="3" r="2.35" fill="none"/>
                </g>
              </g>
            </svg>
          </button>

          <si-overlay :title="$t('filters.title')" @close="expandFilters=false" @apply="expandFilters=false" @revert="unsetFilters" v-if="expandFilters">
            <active-filters class="si-app__active-filters" :value="activeFilters" v-if="hasActiveFilters"/>
            <div class="si-app__separator" v-if="hasActiveFilters"></div>
            <side-filters/>
          </si-overlay>
        </template>
      </main-filters>
    </div>

    <template v-if="$screen.isMobile">
      <div>
        <si-map ref="map" class="si-app__map" v-touch:swipe.bottom="closeList"/>
        <toggle-view/>
        <div class="si-app__list" :class="{ 'si-app__list--open': $route.query.view === 'list' }">
          <div class="si-app__toolbar" v-touch:swipe.bottom="closeList">
            <results/>
            <sorting/>
            <button type="button" :title="$t('view.mapTitle')" @click="showMap">
              <svg width="13.017" height="13.017"><g fill="none" stroke="gray" stroke-width="1.3"><path d="M.46 12.557 12.48.537"/><path d="M12.558 12.48.537.459"/></g></svg>
            </button>
          </div>
          <si-drawer @home="$refs.map.zoomToPlace()"/>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="si-app__meta">
        <active-filters revert :value="activeFilters" @revert="unsetFilters"/>
        <div class="si-app__meta-right">
          <sorting v-show="showSorting"/>
          <results/>
          <toggle-view/>
        </div>
      </div>

      <side-filters class="si-app__filters" :fullscreen="$route.query.view !== 'list'" v-if="$screen.isDesktop"/>

      <si-list class="si-app__list" v-if="$route.query.view === 'list'"/>
      <template v-else>
        <si-drawer class="si-app__drawer" v-touch:swipe="swipeDrawer" @home="$refs.map.zoomToPlace()"/>
        <si-map ref="map" class="si-app__map" v-touch:swipe="swipeDrawer"/>
      </template>
    </template>
  </section>

</template>

<script>
import { computed, defineAsyncComponent } from 'vue';
import idle from '../helpers/idle';
import updateQuery from '../helpers/updateQuery';
import sort from '../helpers/sort';
import filter from '../helpers/filter';
import ActiveFilters from './Fragments/ActiveFilters';
import Results from './Fragments/Results.vue';
import ToggleView from './Fragments/ToggleView';
import MainFilters from './MainFilters'
import Sorting from './MainFilters/Sorting';
import SiOverlay from './Fragments/Overlay.vue';
import asValue from '../helpers/asValue';

import SideFilters from './SideFilters';
import SiList from './ListView/Index.vue';
import SiDrawer from './MapView/Drawer.vue';

const SiMap = defineAsyncComponent(() => import(/* webpackChunkName: "map" */ './MapView/Map.vue').catch(() => { window.location.reload(true) }));

let debounce = undefined;

export default {
  components: { Results, Sorting, ToggleView, ActiveFilters, MainFilters, SideFilters, SiList, SiDrawer, SiMap, SiOverlay },

  inject: ['apiUrl', 'requestToken', 'locations', 'sorting', 'filters'],

  data: () => ({
    expandFilters: false,
    scrollTop: 0,
    scrollPosition: 0,
  }),

  provide () {
    return {
      activeFilters: computed(() => this.activeFilters),
    }
  },

  computed: {
    place: (vm) => vm.$store.place,
    drawer: (vm) => vm.$store.drawer,
    sortingOrder: (vm) => `${ vm.$store.sortBy }${ vm.$store.sortAsc ? 'ASC' : 'DESC' }`,
    isMobile: (vm) => vm.$screen.isMobile,
    hasActiveFilters: (vm) => Object.keys(vm.activeFilters).length > 0 || vm.$store.place,

    showSorting: (vm) => vm.drawer || vm.$route.query?.view === 'list',

    activeFilters () {
      const values = {};

      Object.keys(this.filters).forEach((prop) => {
        const value = this.$route.query[prop] && this.$route.query[prop].split(',').map(asValue);

        if (value) {
          values[prop] = value;
        }
      });

      return values;
    },
  },

  methods: {
    updateLocations () {
      if (debounce) {
        debounce();
      }

      debounce = idle(() => {
        if (this.place) {
          this.$store.sortBy = 'place';
        } else if (this.$store.sortBy === 'place') {
          this.$store.sortBy = 'city';
          this.$store.sortAsc = true;
        }

        const locations = filter(this.$store.locations.all, this.activeFilters, this.$store.place);

        if (locations.length !== this.$store.locations.filtered.length) {
          this.$store.locations.filtered = locations;
        } else {
          const ids = this.$store.locations.filtered.map(l => l.id);

          if (locations.filter(l => ids.includes(l.id)).length !== locations.length) {
            this.$store.locations.filtered = locations;
          }
        }

        this.$store.locations.ready = true;
      });
    },

    unsetFilters () {
      const query = Object.assign({}, this.$route.query);

      delete query.place;
      delete query.canton;
      Object.keys(this.filters).forEach((group) => {
        delete query[group];
      });

      this.$router.push({ query });
      this.expandFilters = false;
    },

    showMap () {
      this.$router.push(updateQuery(this.$route, 'view'));
    },

    scrollTo (top = null, behavior = 'auto') {
      if (behavior === 'instant' || window.scrollY >= this.scrollTop) {
        window.scrollTo({
          top: top || this.scrollTop,
          behavior
        });
      }
    },

    storePosition () {
      const meta = document.querySelector('.si-app__meta');
      this.scrollTop = meta ? meta.offsetTop - 95 : 0;
    },

    closeList () {
      if (this.$route.query.view === 'list') {
        this.$router.push(updateQuery(this.$route, 'view'));
      }
    },

    swipeDrawer (direction) {
      if (direction === 'left') {
        this.$store.drawer = false;
      } else if (direction === 'right') {
        this.$store.drawer = true;
      }
    },

    print (printUrl) {
      if (this.$store.locations.all.length === this.$store.locations.filtered.length) {
        window.open(printUrl);
        return;
      }

      const form = document.createElement('form');
      form.method = 'POST';
      form.action = printUrl;
      form.target = '_blank';

      const data = {
        REQUEST_TOKEN: this.requestToken,
        ids: sort(this.$store.locations.filtered, this.$store.sortBy, this.$store.sortAsc, this.sorting, this.$store.place).map(l => l.id).join(','),
      };

      Object.keys(data).forEach((k) => {
        const field = document.createElement('input');
        field.type = 'hidden';
        field.name = k;
        field.value = data[k];

        form.appendChild(field);
      });

      document.body.appendChild(form);
      form.submit();
    }
  },

  watch: {
    $route () {
      this.updateLocations();
    },

    place () {
      this.updateLocations();
    },

    isMobile () {
      this.storePosition();
    },

    drawer (value) {
      if (value) {
        this.scrollTo(null, 'instant');
      }
    },

    sortingOrder () {
      this.scrollTo();
    }
  },

  async mounted () {
    this.$store.locations.all = this.locations;
    this.$store.locations.apiUrl = this.apiUrl;

    this.storePosition();

    let debounce;
    document.addEventListener('scroll', () => {
      clearTimeout(debounce);
      debounce = setTimeout(() => {
        if (window.scrollY >= this.scrollTop) {
          this.$refs.header.style.height = `${ this.$refs.header.offsetHeight }px`;
          document.body.classList.add('layout--sticky');
        } else {
          document.body.classList.remove('layout--sticky');
          this.$refs.header.style.height = 'auto';
        }
      }, 50);
    });

    document.querySelector('.section-subheader__link--print a').addEventListener('click', (e) => {
      e.preventDefault();
      this.print(e.target.href);
    });

    this.$router.beforeEach((to, from) => {
      if (!from.query.view && to.query.view && to.query.view !== 'list') {
        this.scrollPosition = window.scrollY;
        this.scrollTo(null, 'instant');
      }
    })

    this.$router.afterEach((to, from) => {
      if (!from.query.view && to.query.view && to.query.view !== 'list') {
        return;
      }

      if (from.query.view && !to.query.view && from.query.view !== 'list') {
        setTimeout(() => this.scrollTo(this.scrollPosition, 'instant'), 50);
      } else {
        setTimeout(() => this.scrollTo(), 50);
      }
    });

    this.updateLocations();
  }
}
</script>

<style lang="scss">
@import "../../styles/defaults";

.layout--suchtindex .section-main {
  .mod_article {
    display: none;

    @include media(tablet) {
      display: block;
    }
  }

  h1 {
    margin: 55px 0 35px;
    font-size: 26px;
    line-height: 1;
    font-weight: map-get($font-weight, light);
    text-align: center;

    strong {
      color: map-get($font-color, hover);
      font-weight: map-get($font-weight, light);
    }

    @include media(desktop) {
      font-size: 32px;
    }
  }
}

.si-app {
  height: calc(100vh - 50px);
  height: calc((var(--vh, 1vh) * 100) - 50px);

  &__filter-toggle {
    margin: 5px 0 0;
    padding: 10px;
    background: none;
    border: none;
    cursor: pointer;
  }

  &__separator {
    height: 1px;
    margin: 0 -25px;
    background: map-get($borders, light);
  }

  &__list {
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: 0;
    background: #FFF;
    z-index: 10;
    transition: height .3s ease-in-out;

    &--open {
      height: 70vh;
      height: calc((var(--vh, 1vh) * 70));
    }
  }

  &__toolbar {
    display: flex;
    align-items: center;
    padding: 10px 25px 5px;
    font-size: 11px;

    > span {
      flex-grow: 1;
    }

    > button {
      margin-left: 10px;
      padding: 13px 1px 10px 10px;
      background: none;
      border: none;
      cursor: pointer;
    }
  }
}

@include media(mobile-only) {
  body {
    overflow: hidden;
  }

  .section-service,
  .section-prefooter,
  .section-footer {
    display: none;
  }

  .section-subheader {
    &__breadcrumb {
      display: none;
    }

    &__links {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;

      ul {
        justify-content: center;
        align-items: center;
      }
    }
  }
}

@include media(tablet, '.si-app') {
  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: auto 2fr;
  height: auto;

  &__header {
    grid-row: 1;
    grid-column: 1/3;
  }

  &__meta {
    grid-row: 2;
    grid-column: 1/3;
    position: sticky;
    top: 95px;
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 10px 30px 0 22px;
    background: #FFF;
    z-index: 20;

    &-right {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 20px;
      white-space: nowrap;
    }
  }

  &__list {
    grid-row: 3;
    grid-column: 1/3;
    margin: 0 20px;

    position: static;
    width: auto;
    height: auto;
    background: none;
    transition: none;
  }

  &__drawer {
    grid-row: 3;
    grid-column: 1;
  }

  &__map {
    grid-row: 3;
    grid-column: 2;
  }
}

@include media(desktop, '.si-app') {
  grid-template-columns: minmax(auto, 400px) auto 2fr;
  max-width: 1680px;
  margin: 0 auto;

  &--list {
    max-width: 1200px;
  }

  &__header {
    grid-column: 1/4;
  }

  &__meta {
    grid-column: 1/4;
    top: 95px;
    padding-left: 50px;
    padding-right: 50px;

    .si-app--list & {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__filters {
    grid-row: 3;
    grid-column: 1;
    position: sticky;
    top: 185px;
    max-height: calc(100vh - 185px - 62px); // Header + Pagination
    max-height: calc((var(--vh, 1vh) * 100) - 185px - 62px);
    margin-right: 10px;
    overflow-y: auto;
  }

  &__list {
    grid-column: 2/4;
    margin: 0;
  }

  &__drawer {
    grid-column: 2;
  }

  &__map {
    grid-column: 3;
  }
}

@include media(1681, '.si-app') {
  &__meta {
    padding-right: 0;
  }
}
</style>
