import { createApp } from 'vue';
import { createRouter, createWebHashHistory } from 'vue-router';
import { createI18n } from 'vue-i18n';
import { createMedia } from './vue/media';
import { createStore } from './vue/store';
import Vue3TouchEvents from "vue3-touch-events";
import { loadSentry, captureWarning } from './vue/helpers/sentry';
import App from './vue/components/App';

const locale = document.documentElement.lang;
const i18n = createI18n({
    locale,
    messages: { [locale]: window.MESSAGES },
    missing: (locale, key) => {
        captureWarning(`i18n :: Key "${ key }" not found for locale "${ locale }"`);
    }
});

const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: '/',
            component: App
        },
        { path: '/:pathMatch(.*)*', redirect: '/' },
    ]
});

const app = createApp(App);
app.config.unwrapInjectedRef = true;
app.use(i18n);
app.use(router);
app.use(createMedia());
app.use(createStore());
app.use(Vue3TouchEvents, {
    disableClick: true,
    swipeTolerance: 50
});

window.suchtindex = app;

loadSentry(app);
