// @see https://github.com/yousufkalim/nearby-sort
const getDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1); // deg2rad below
    const dLon = deg2rad(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
};

// Deg conversion
const deg2rad = (deg) => {
    return deg * (Math.PI / 180);
};


function nearby (data, place) {
    return data.sort((a, b) => {
        if (!a.coordinates && !b.coordinates) {
            return 0;
        } else if (!a.coordinates) {
            return -1;
        } else if (!b.coordinates) {
            return 1;
        }

        const distanceA = getDistance(place.lat, place.lng, a.coordinates[0], a.coordinates[1]);
        const distanceB = getDistance(place.lat, place.lng, b.coordinates[0], b.coordinates[1]);

        return distanceA - distanceB;
    });
}

export default function (data, order, asc = true, sorting, place) {
    if (order === 'place') {
        if (place) {
            return nearby(data, place);
        }

        order = 'name';
        asc = true;
    }

    const sort = sorting[order];

    return data.sort((a, b) => {
        return asc ? sort.indexOf(a.id) - sort.indexOf(b.id) : sort.indexOf(b.id) - sort.indexOf(a.id);
    });
}
