<template>
  <div ref="overlay" class="si-overlay" :class="{ 'si-overlay--up': up }">
    <div class="si-overlay__toolbar" v-if="!$screen.isDesktop">
      <button type="button" class="si-overlay__close" @click="$emit('close')">
        <svg width="13.017" height="13.017"><g fill="none" stroke="gray" stroke-width="1.3"><path d="M.46 12.557 12.48.537"/><path d="M12.558 12.48.537.459"/></g></svg>
      </button>
      <div class="si-overlay__title">{{ title }}</div>
      <button type="button" class="si-overlay__revert" @click="$emit('revert')">
        <svg width="18.346" height="14.417">
          <g fill="#fff">
            <path d="M11.31 13.767c-1.287 0-2.606-.408-3.526-1.09-1-.734-1.802-1.68-2.203-2.596-.454-1.017-.656-1.902-.656-2.873C4.925 3.592 7.79.65 11.311.65s6.386 2.942 6.386 6.558c0 3.617-2.865 6.559-6.386 6.559Z"/>
            <path d="M11.31 1.3c-3.162 0-5.735 2.65-5.735 5.908 0 .875.184 1.678.597 2.604l.004.008c.352.803 1.096 1.675 1.99 2.332l.005.003c.812.602 1.986.962 3.14.962 3.163 0 5.736-2.65 5.736-5.909 0-3.258-2.573-5.908-5.736-5.908m0-1.3c3.886 0 7.036 3.227 7.036 7.208 0 3.981-3.15 7.209-7.036 7.209-1.531 0-2.948-.501-3.914-1.218-1.122-.824-1.98-1.873-2.412-2.857-.497-1.115-.71-2.099-.71-3.134C4.275 3.228 7.425 0 11.311 0Z" fill="gray"/>
          </g>
          <path data-name="Pfad 3619" d="m.746 6.898 1.247 7.062 9.192-3.931-2.989-3.131Z" fill="#fff"/>
          <path data-name="Pfad 3531" d="M9.982 4.88 4.657 7.364l-2.49-5.327" fill="none" stroke="gray" stroke-width="1.3"/>
        </svg>
      </button>
    </div>

    <div class="si-overlay__control" v-if="$slots.control">
      <slot name="control"></slot>
    </div>

    <div
        class="si-overlay__content"
        :class="{ 'si-overlay__content--apply': !$slots.control, 'si-overlay__content--control': $slots.control }"
        :style="$screen.isDesktop && maxHeight ? `max-height:${maxHeight}px` : ''"
    >
      <slot/>
    </div>

    <button class="si-overlay__apply" @click="$emit('apply')" v-if="!$slots.control && !$screen.isDesktop">{{ $t('filters.apply') }}</button>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    maxHeight: 0,
  },

  data: () => ({
    overflow: null,
    up: false,
  }),

  mounted () {
    this.overflow = document.body.style.overflow;
    if (!this.$screen.isDesktop) {
      document.body.style.overflow = 'hidden';
    }

    const position = this.$refs.overlay.getBoundingClientRect();
    if (position.top > position.height + 100 && window.innerHeight - position.y - position.height < position.y) {
      this.up = true;
    }
  },

  unmounted () {
    document.body.style.overflow = this.overflow;
  }
}
</script>

<style lang="scss">
@import "../../../styles/defaults";

.si-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background: #FFF;
  z-index: 102;
  animation: .3s ease-in-out sticky;

  &__toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 60px;
    font-size: 17px;
    font-weight: map-get($font-weight, medium);
    line-height: 20px;
    border-bottom: 1px solid map-get($borders, light);
  }

  &__title {
    padding: 16px 20px;
  }

  &__control {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }

  &__close,
  &__revert {
    flex-shrink: 0;
    width: 67px;
    height: 59px;
    background: none;
    border: none;
    cursor: pointer;
  }

  &__content {
    height: calc(100vh - 60px);
    height: calc((var(--vh, 1vh) * 100) - 60px);
    overflow-y: auto;
    padding: 0 25px;

    &--apply {
      height: calc(100vh - 166px);
      height: calc((var(--vh, 1vh) * 100) - 166px);
    }

    &--control {
      height: calc(100vh - 120px);
      height: calc((var(--vh, 1vh) * 100) - 120px);
    }
  }

  &__apply {
    display: block;
    width: calc(100vw - 50px);
    height: 56px;
    margin: 25px;
    padding: 16px 15px 14px;
    color: map-get($font-color, link);
    font-size: 13px;
    font-weight: map-get($font-weight, bold);
    line-height: 15px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    background: #FFF;
    border: 1.5px solid map-get($borders, main);
    cursor: pointer;

    &:hover {
      color: #FFF;
      background: map-get($backgrounds, hover);
    }
  }
}

@include media(desktop, '.si-overlay') {
  position: absolute;
  top: calc(100% + 10px);
  width: 100%;
  height: auto;
  margin: 0;
  padding: 5px 0;
  border: 1px solid map-get($borders, default);
  border-radius: 6px;

  &--up {
    top: auto;
    bottom: calc(100% + 10px);
  }

  &__content {
    height: auto;
    padding: 0;
  }
}
</style>
